import React, { useState, useEffect } from "react";
import { Alert, Space, Breadcrumb, List, Select } from "antd";
import { Link } from "react-router-dom";

const { Option } = Select;

const OverviewV2 = () => {
  

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="">Reporting</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Overview v2</Breadcrumb.Item>
      </Breadcrumb>
      <hr />
      
    </>
  );
};
export default OverviewV2;
 
