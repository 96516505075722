import React from 'react'

const EmailAccounts = () => {
  return (
    <div>
      EmailAccounts
    </div>
  )
}

export default EmailAccounts
