import React, { useState, useEffect } from "react";
import {
  Button,
  Space,
  Input,
  Upload,
  message,
  Select,
  Form,
  DatePicker,
} from "antd";
import {
  AiOutlineSearch,
  AiOutlineFilter,
  AiOutlinePlusCircle,
  AiOutlineEdit,
  AiOutlineDelete,
  AiOutlineExclamationCircle,
  AiOutlineFileExcel,
  AiOutlineFileText,
  AiOutlineImport,
} from "react-icons/ai";
import { space } from "postcss/lib/list";
import { color } from "framer-motion";

const { Option } = Select; // Add this line to import the Option component
const { RangePicker } = DatePicker;




//-----------------import export functions------------------
const handleCsvExport = () => {
  // Logic to export data as CSV
  message.success("CSV export logic goes here");
};

const handleExcelExport = () => {
  // Logic to export data as Excel
  message.success("Excel export logic goes here");
};

const handleImport = (file) => {
  // Logic to handle file import
  message.success(`File ${file.name} uploaded successfully`);
};

const importProps = {
  beforeUpload: (file) => {
    // Disable default upload behavior
    return false;
  },
  onChange: (info) => {
    if (info.file.status === "done") {
      handleImport(info.file.originFileObj);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};

const NewFilter = ({ onClick }) => {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [labelValue, setLabelValue] = useState("");

  const handleDateChange = (dates) => {
    if (dates && dates.length === 2) {
      setFromDate(dates[0]);
      setToDate(dates[1]);
    }
  };

  const handleInputChange = (e, field) => {
    const value = e.target.value;
    setLabelValue((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
  };

  return (
    <Space>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ marginRight: "20px" }}>
          <RangePicker onChange={handleDateChange} />
        </div>
        <label style={{ marginRight: "10px" }}>Based On :</label>
        <div style={{ marginRight: "10px" }}>
          <DatePicker
            placeholder="Date of Complaint"
            value={labelValue["label1"]}
            onChange={(value) => handleInputChange(value, "label1")}
            style={{ width: 160 }}
          />
        </div>
        : :
        <div style={{ marginLeft: "10px" }}>
          <Select
            placeholder="-Area-"
            value={labelValue["label2"]}
            onChange={(value) => handleInputChange(value, "label2")}
            style={{ width: 160 }}
          >
            <Option value="option1">Option 1</Option>
            <Option value="option2">Option 2</Option>
            <Option value="option3">Option 3</Option>
          </Select>
        </div>
      </div>
    </Space>
  );
};

const SearchInput = ({ onClick }) => {
  return (
    <Space>
      {/* <Button icon={<AiOutlineQuestionCircle />}>Support</Button> */}
      <Input
        placeholder="Search"
        prefix={<AiOutlineSearch style={{ marginRight: 8 }} />}
      />
      <Button
        type="primary"
        icon={<AiOutlineFilter />}
        style={{ marginLeft: 8 }}
      >
        Filter
      </Button>
    </Space>
  );
};

const AddEditDeleteDeactivate = ({
  onClickNew,
  onClickEdit,
  onClickDelete,
}) => {
  return (
    <Space>
      <Button
        type="primary"
        style={{ backgroundColor: "green", borderColor: "green" }}
        icon={<AiOutlinePlusCircle />}
        onClick={onClickNew}
        success
      >
        New
      </Button>
      <Button type="primary" icon={<AiOutlineEdit />} onClick={onClickEdit}>
        Edit
      </Button>
      <Button
        type="primary"
        style={{ backgroundColor: "#ff4d4f" }}
        icon={<AiOutlineDelete />}
        onClick={onClickDelete}
        danger
      >
        Delete
      </Button>
      <Button
        type="primary"
        style={{ backgroundColor: "#faad14", borderColor: "#faad14" }}
        icon={<AiOutlineExclamationCircle />}
      >
        Deactivate
      </Button>
    </Space>
  );
};

const AddEditDelete = ({ onClickNew, onClickEdit, onClickDelete }) => {
  return (
    <Space>
      <Button
        type="primary"
        style={{ backgroundColor: "green", borderColor: "green"}}
        icon={<AiOutlinePlusCircle />}
        onClick={onClickNew}
        success
      >
        New
      </Button>
      <Button type="primary" icon={<AiOutlineEdit />} onClick={onClickEdit} >
        Edit
      </Button>
      <Button
        type="primary"
        style={{ backgroundColor: "#ff4d4f" }}
        icon={<AiOutlineDelete />}
        onClick={onClickDelete}
        danger
      >
        Delete
      </Button>
    </Space>
  );
};

const CsvExcelImport = ({ handleCsvExport, handleExcelExport }) => {
  return (
    <Space>
      <Button icon={<AiOutlineFileText />} onClick={handleCsvExport}>
        CSV
      </Button>

      <Button icon={<AiOutlineFileExcel />} onClick={handleExcelExport}>
        Excel
      </Button>
      <Upload {...importProps}>
        <Button icon={<AiOutlineImport />}>Import</Button>
      </Upload>
    </Space>
  );
};

const CsvExcelImport1 = ({ handleCsvExport, handleExcelExport }) => {
  return (
    <Space>
      <Button icon={<AiOutlineFileText />} onClick={handleCsvExport}>
        CSV
      </Button>

      <Button icon={<AiOutlineFileExcel />} onClick={handleExcelExport}>
        Excel
      </Button>
    </Space>
  );
};

const CsvExcelImport2 = ({ handleCsvExport, handleExcelExport }) => {
  return (
    <Space>
      <CsvExcelImport1
        handleCsvExport={handleCsvExport}
        handleExcelExport={handleExcelExport}
      />
      <Upload {...importProps}>
        <Button icon={<AiOutlineImport />}>File Manager</Button>
      </Upload>
    </Space>
  );
};

const FiltersDropdown = ({
  handleFilter1Change,
  handleFilter2Change,
  handleFilter3Change,
  filterValue1,
  filterValue2,
  filterValue3,
}) => {
  return (
    <Space>
      {/* New dropdowns for filtering */}
      <Select
        style={{ width: 120 }}
        placeholder="Online"
        onChange={handleFilter1Change}
        value={filterValue1}
      >
        {/* <Option value="online">Online</Option> */}
        <Option value="yes">Yes</Option>
        <Option value="no">No </Option>

        {/* Add more options as needed */}
      </Select>
      <Select
        style={{ width: 120 }}
        placeholder="--Not available separately--"
        onChange={handleFilter2Change}
        value={filterValue2}
      >
        {/* <Option value="" >--Not available separately--</Option> */}
        <Option value="yes">Yes</Option>
        <Option value="no">No </Option>

        {/* Add more options as needed */}
      </Select>
      <Select
        style={{ width: 120 }}
        placeholder="--Validity--"
        onChange={handleFilter3Change}
        value={filterValue3}
      >
        <Option value="activated">Activated</Option>
        <Option value="deactivated">Deactivated</Option>
        {/* Add more options as needed */}
      </Select>
    </Space>
  );
};

const FiltersDropdown1 = ({
  handleFilter1Change,
  handleFilter2Change,
  filterValue1,
  filterValue2,
}) => {
  return (
    <Space>
      <Select
        style={{ width: 120 }}
        placeholder="SELECT TYPE"
        onChange={handleFilter1Change}
        value={filterValue1}
      >
        {/* <Option value="online">Online</Option> */}
        <Option value="All">All</Option>
        <Option value="Normal">Normal</Option>
        <Option value="Internal">Internal </Option>

        {/* Add more options as needed */}
      </Select>

      <Select
        style={{ width: 120 }}
        placeholder="--Validity--"
        onChange={handleFilter2Change}
        value={filterValue2}
      >
        <Option value="activated">Activated</Option>
        <Option value="deactivated">Deactivated</Option>
        {/* Add more options as needed */}
      </Select>
    </Space>
  );
};

const FiltersDropdown2 = ({ handleFilter1Change, filterValue1 }) => {
  return (
    <Space>
      <Select
        style={{ width: 120 }}
        placeholder="--Validity--"
        onChange={handleFilter1Change}
        value={filterValue1}
      >
        <Option value="activated">Activated</Option>
        <Option value="deactivated">Deactivated</Option>
        {/* Add more options as needed */}
      </Select>
    </Space>
  );
};

const FiltersDropdown3 = ({
  handleFilter1Change,
  handleFilter2Change,
  filterValue1,
  filterValue2,
}) => {
  return (
    <Space>
      <Select
        style={{ width: 120 }}
        placeholder="--Season--"
        onChange={handleFilter1Change}
        value={filterValue1}
      >
        <Option value="activated">Activated</Option>
        <Option value="deactivated">Deactivated</Option>
      </Select>
      <Select
        style={{ width: 120 }}
        placeholder="--Transfer provider--"
        onChange={handleFilter2Change}
        value={filterValue2}
      >
        <Option value="activated">Activated</Option>
        <Option value="deactivated">Deactivated</Option>
      </Select>
    </Space>
  );
};

const FiltersDropdown4 = ({
  handleFilter1Change,
  handleFilter2Change,
  filterValue1,
  filterValue2,
}) => {
  return (
    <Space>
      <Select
        style={{ width: 120 }}
        placeholder="--Validity--"
        onChange={handleFilter1Change}
        value={filterValue1}
      >
        <Option value="activated">Activated</Option>
        <Option value="deactivated">Deactivated</Option>
      </Select>
      <Select
        style={{ width: 120 }}
        placeholder="--School--"
        onChange={handleFilter2Change}
        value={filterValue2}
      >
        <Option value="speakuplondon">speakuplondon</Option>
      </Select>
    </Space>
  );
};

const FiltersDropdown5 = ({ handleFilter1Change, filterValue1 }) => {
  return (
    <Space>
      <Select
        style={{ width: 150 }}
        placeholder="Select Country"
        onChange={handleFilter1Change}
        value={filterValue1}
      >
        <Option value="A">A</Option>
        <Option value="B">B </Option>
      </Select>
    </Space>
  );
};

const FiltersDropdownQuestion = ({
  handleFilter1Change,
  handleFilter2Change,
  handleFilter3Change,
  filterValue1,
  filterValue2,
  filterValue3,
}) => {
  return (
    <Space>
      {/* New dropdowns for filtering */}
      <Select
        style={{ width: 160 }}
        placeholder="-Topic-"
        onChange={handleFilter1Change}
        value={filterValue1}
      >
        {/* <Option value="online">Online</Option> */}
        <Option value="option1">option1</Option>
        <Option value="option2">option2</Option>

        {/* Add more options as needed */}
      </Select>
      <Select
        style={{ width: 160 }}
        placeholder="--Depending On--"
        onChange={handleFilter2Change}
        value={filterValue2}
      >
        {/* <Option value="" >--Not available separately--</Option> */}
        <Option value="option1">option1</Option>
        <Option value="option2">option2</Option>

        {/* Add more options as needed */}
      </Select>
      <Select
        style={{ width: 160 }}
        placeholder="--Type of Answer--"
        onChange={handleFilter3Change}
        value={filterValue3}
      >
        <Option value="option1">option1</Option>
        <Option value="option2">option2</Option>
      </Select>
    </Space>
  );
};

const FiltersDropdownSponsor = ({
  handleFilter1Change,
  handleFilter2Change,
  filterValue1,
  filterValue2,
}) => {
  return (
    <Space>
      <Select
        style={{ width: 120 }}
        placeholder="--Validity--"
        onChange={handleFilter1Change}
        value={filterValue1}
      >
        <Option value="activated">Activated</Option>
        <Option value="deactivated">Deactivated</Option>
      </Select>
      <Select
        style={{ width: 120 }}
        placeholder="--Country--"
        onChange={handleFilter2Change}
        value={filterValue2}
      >
        <Option value="option">option</Option>
      </Select>
    </Space>
  );
};

const FiltersDropdownPdf = ({
  handleFilter1Change,
  handleFilter2Change,
  filterValue1,
  filterValue2,
}) => {
  return (
    <Space>
      <Select
        style={{ width: 120 }}
        placeholder="--Type--"
        onChange={handleFilter1Change}
        value={filterValue1}
      >
        <Option value="activated">Activated</Option>
        <Option value="deactivated">Deactivated</Option>
      </Select>
      <Select
        style={{ width: 120 }}
        placeholder="--School--"
        onChange={handleFilter2Change}
        value={filterValue2}
      >
        <Option value="speakuplondon">speakuplondon</Option>
      </Select>
    </Space>
  );
};

const FiltersDropdownEmailTemplate2 = ({
  handleFilter1Change,
  handleFilter2Change,
  filterValue1,
  filterValue2,
}) => {
  return (
    <Space>
      <Select
        style={{ width: 220 }}
        placeholder="-Available in the following overview-"
        onChange={handleFilter1Change}
        value={filterValue1}
      >
        <Option value="option1">option1</Option>
        <Option value="option">option</Option>
      </Select>
      <Select
        style={{ width: 220 }}
        placeholder="-Available for the following schools-"
        onChange={handleFilter2Change}
        value={filterValue2}
      >
        <Option value="speakuplondon">speakuplondon</Option>
      </Select>
    </Space>
  );
};

const FiltersDropdownSMSTemplate = ({
  handleFilter1Change,
  handleFilter2Change,
  filterValue1,
  filterValue2,
}) => {
  return (
    <Space>
      <Select
        style={{ width: 115 }}
        placeholder="-Overview-"
        onChange={handleFilter1Change}
        value={filterValue1}
      >
        <Option value="option1">option1</Option>
        <Option value="option">option</Option>
      </Select>
      <Select
        style={{ width: 110 }}
        placeholder="-Schools-"
        onChange={handleFilter2Change}
        value={filterValue2}
      >
        <Option value="speakuplondon">speakuplondon</Option>
      </Select>
      <Select
        style={{ width: 155 }}
        placeholder="-Recipient group-"
        onChange={handleFilter1Change}
        value={filterValue1}
      >
        <Option value="option1">option1</Option>
        <Option value="option">option</Option>
      </Select>
      <Select
        style={{ width: 215 }}
        placeholder="-Correspondencr language-"
        onChange={handleFilter2Change}
        value={filterValue2}
      >
        <Option value="speakuplondon">speakuplondon</Option>
      </Select>
      : :
      <Select
        style={{ width: 100 }}
        placeholder="--Type--"
        onChange={handleFilter1Change}
        value={filterValue1}
      >
        <Option value="activated">Activated</Option>
        <Option value="deactivated">Deactivated</Option>
      </Select>
    </Space>
  );
};

const FiltersDropdownEventControl = ({
  handleFilter1Change,
  handleFilter2Change,
  filterValue1,
  filterValue2,
}) => {
  return (
    <Space>
      <Select
        style={{ width: 115 }}
        placeholder="-Event-"
        onChange={handleFilter1Change}
        value={filterValue1}
      >
        <Option value="option1">option1</Option>
        <Option value="option">option</Option>
      </Select>
      <Select
        style={{ width: 110 }}
        placeholder="-User-"
        onChange={handleFilter2Change}
        value={filterValue2}
      >
        <Option value="option1">option1</Option>
        <Option value="option">option</Option>
      </Select>
      : :
      <Select
        style={{ width: 110 }}
        placeholder="-Type-"
        onChange={handleFilter1Change}
        value={filterValue1}
      >
        <Option value="activated">Activated</Option>
        <Option value="deactivated">Deactivated</Option>
      </Select>
    </Space>
  );
};

const FiltersDropdownEmailSpool = ({
  handleFilter1Change,
  handleFilter2Change,
  filterValue1,
  filterValue2,
}) => {
  return (
    <Space>
            <DatePicker.RangePicker style={{ width: 300 }} />

      <Select
        style={{ width: 100 }}
        placeholder="--Type--"
        onChange={handleFilter1Change}
        value={filterValue1}
      >
        <Option value="activated">Activated</Option>
        <Option value="deactivated">Deactivated</Option>
      </Select>
      : :
      <Select
        style={{ width: 155 }}
        placeholder="-Category-"
        onChange={handleFilter1Change}
        value={filterValue1}
      >
        <Option value="option1">option1</Option>
        <Option value="option">option</Option>
      </Select>
      
    </Space>
  );
};

const FiltersDropdownLog = ({ handleFilter1Change, filterValue1 }) => {
  return (
    <Space>
      <Select
        style={{ width: 120 }}
        placeholder="--Action--"
        onChange={handleFilter1Change}
        value={filterValue1}
      >
        <Option value="Option1">Option1</Option>
        <Option value="Option2">Option2</Option>
      </Select>

      <RangePicker />
    </Space>
  );
};

const SubmitCancelButtonGroup = ({ recordData, CancelBothModel }) => {
  return (
    <Form.Item wrapperCol={{ offset: 10, span: 16 }}>
      <div style={{ marginRight: "8px", display: "inline-block" }}>
        <Button type="primary" htmlType="submit">
          {recordData ? "Update" : "Save"}
        </Button>
      </div>
      <Button type="primary" htmlType="reset" onClick={CancelBothModel}>
        Cancel
      </Button>
    </Form.Item>
  );
};

const FileImportBtn = ({
  onClickNew,
  onClickEdit,
  onClickDelete,
  handleFileImport,
}) => {
  return (
    <space>
      <AddEditDelete
        onClickNew={onClickNew}
        onClickEdit={onClickEdit}
        onClickDelete={onClickDelete}
      />
      <Upload {...importProps}>
        <Button icon={<AiOutlineImport />} style={{ marginLeft: "14" }}>
          File Manager
        </Button>
      </Upload>
    </space>
  );
};

const FiltersDropdownAgentPayment = ({
  handleFilter1Change,
  handleFilter2Change,
  filterValue1,
  filterValue2,
}) => {
  return (
    <Space>
      <Select
        style={{ width: 120 }}
        placeholder="--Agency--"
        onChange={handleFilter1Change}
        value={filterValue1}
      >
        {/* <Option value="online">Online</Option> */}
        <Option value="Agency">Agency</Option>
        

        {/* Add more options as needed */}
      </Select>

      <Select
        style={{ width: 120 }}
        placeholder="--Payment status--"
        onChange={handleFilter2Change}
        value={filterValue2}
      >
        <Option value="Payment status">Payment status</Option>
       
      </Select>
    </Space>
  );
};


const SaveBtn = ({ CancelBothModel }) => {
  return (
    <>
      <Form.Item wrapperCol={{ offset: 10, span: 16 }}>
        <div style={{ marginRight: "8px", display: "inline-block" }}>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </div>
        <Button type="primary" htmlType="reset" onClick={CancelBothModel}>
          Cancel
        </Button>
      </Form.Item>
    </>
  );
};

const UpdateBtn = ({ CancelBothModel }) => {
  return (
    <>
      <Form.Item wrapperCol={{ offset: 10, span: 16 }}>
        <div style={{ marginRight: "8px", display: "inline-block" }}>
          <Button type="primary" htmlType="submit">
            Update
          </Button>
        </div>
        <Button type="primary" htmlType="reset" onClick={CancelBothModel}>
          Cancel
        </Button>
      </Form.Item>
    </>
  );
};

export {
  SearchInput,
  AddEditDeleteDeactivate,
  AddEditDelete,
  CsvExcelImport,
  FiltersDropdown,
  SubmitCancelButtonGroup,
  FileImportBtn,
  SaveBtn,
  UpdateBtn,
  FiltersDropdown1,
  FiltersDropdown2,
  FiltersDropdown3,
  FiltersDropdown4,
  FiltersDropdownEventControl,
  CsvExcelImport1,
  FiltersDropdownPdf,
  FiltersDropdownEmailTemplate2,
  FiltersDropdownSMSTemplate,
  FiltersDropdown5,
  CsvExcelImport2,
  NewFilter,
  FiltersDropdownQuestion,FiltersDropdownLog,
  FiltersDropdownSponsor,FiltersDropdownEmailSpool,FiltersDropdownAgentPayment
};
