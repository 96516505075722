import React from 'react'

const SchoolHolidays = () => {
  return (
    <div>
      SchoolHolidays
    </div>
  )
}

export default SchoolHolidays
