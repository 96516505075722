import React from 'react'

const CourseStructure = () => {
  return (
    <div>
      CourseStructure
    </div>
  )
}

export default CourseStructure
