import React from 'react'

const EmailTemplate2Image = () => {
  return (
    <div>
      
    </div>
  )
}

export default EmailTemplate2Image
