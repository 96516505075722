import React from 'react';

const Classes = () => {
  return (
    <div>
      <h3>Allocations</h3>
      <hr />
      <h3>The student has not been assigned to a class yet.</h3>
    </div>
  );
}

export default Classes;
